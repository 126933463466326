import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PoerfolioImageOne from '../images/our_work.jpg'
import JayyogeshwarService from '../images/jayyogeshwar_service.png'
import NeighbourcareSerice from '../images/neighbourcare_serice.png'
import SoniincorporationSservice from '../images/soniincorporation_service.png'

export default function ServicePagePortfolio() {
	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
			  breakpoint: 1466,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true
			  }
			}, {
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 2
			  }
			},
			 {
			  breakpoint: 1199,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			  }
			}, {
			  breakpoint: 768,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}, {
			  breakpoint: 575,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}]
		
	  };
  return (
	<div className="slick_slider_dots">
		<div className="flex flex-wrap flex-col justify-center service_section work_section xl:min-h-screen pt-8 pb-8 sm:pt-10 sm:pb-10 lg:pt-14 lg:pb-14 overflow-hidden">
			<div className="container">
				<div className="text_content pb-8 lg:pb-10 2xl:pb-12">
					<h4 className="text-26 md:text-28 lg:text-30 xl:text-32 2xl:text-35"><strong>Web Development Services
					</strong><span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
				</div>
			</div>
			<div className="container">
				<div className="-mx-8">
					<Slider className="service_slider_wrapper" {...settings}> 
						<div className="box pb-10 px-8">
							<div className="inner group bg-white  text-center relative h-auto">
								<img className="lazyload" loading="lazy" data-src={JayyogeshwarService} alt="service"/>
								<div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
							</div>
							<h6 className="pt-10 ">Jay Yogeshwar Petro Chemical</h6>
							<p className="pt-2 xl:pt-3 2xl:pt-4">JAY YOGESHWAR PETRO CHEMICALS is a leading global provider for oil and gas services. We are specialist for Oil Refining, Oil Trading, Oil storage, Diesel trading, Shipping and Logistics.</p>
						</div>
						<div className="box pb-10 px-8">
							<div className="inner group bg-white text-center relative h-auto">
								<img className="lazyload" loading="lazy" data-src={SoniincorporationSservice} alt="service"/>
								<div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
							</div>
							<h6 className="pt-10 ">Soni Incorporation</h6>
							<p className="pt-2 xl:pt-3 2xl:pt-4">We soni incorporation is an Ahmedabad based company, we are highly qualified to be your manufacturing, design and supply chain partner we help in increase productivity by proving supply of projects, prototype and mass productions solutions with a wide range of industries with proven record. We proudly offer cost effective and high quality solutions for any type of orders.</p>
						</div>
						<div className="box pb-10 px-8">
							<div className="inner group bg-white text-center relative h-auto">
								<img className="lazyload" loading="lazy" data-src={NeighbourcareSerice} alt="service"/>
								<div className="left-m15 right-m-15 top-m-15 bottom-m-15 2xl:left-m20 2xl:right-m-20 2xl:top-m-20 2xl:bottom-m-20  absolute bg-lightblue z-1"></div>
							</div>
							<h6 className="pt-10 ">neighbourcare</h6>
							<p className="pt-2 xl:pt-3 2xl:pt-4">NeighbourCare is a Newborn in the Industry and Growing rapidly to serve community people with vision to serve through their own ‘PrimaryCare Center’ comprise precise diagnosis to effective medicines at affordable price.</p>
						</div>
					</Slider>
				</div>
			</div>
		</div>
	</div>
  );
}







