import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import { Link } from 'gatsby'
import MainServiceBannerImage from '../images/main_service_image.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ShopifyLogo from '../images/shopify.svg'
import LaravelLogo from '../images/laravel.svg'
import WordpressLogo from '../images/wordpress.svg'
import CodeigniterLogo from '../images/codeigniter.svg'
import PhpLogo from '../images/php.svg'
import MagentoLogo from '../images/magento.svg'
import AndroidLogo from '../images/android.svg'
import AppleLogo from '../images/apple.svg'
import FlutterLogo from '../images/flutter.svg'
import GraphicLogo from '../images/graphic_logo.svg'
import QaLogo from '../images/qa_logo.svg'
import DigitalMarketingLogo from '../images/digital_marketing_logo.svg'
import 'lazysizes'
import Layout from '../components/layout'
import PortfolioService from '../components/service-page-portfolio'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'


const ServicesPage = () => {
  return (
    <Layout>
		<Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/services" />
		  <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Services" description='eWise - Your end-to-end digital partner'/>
    <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24 overflow-hidden">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative pb-3"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90">Services</strong> </h1>
                                <div className="text">
                                    <p className="mb-3">We are not just a recognized IT company but also a group of self-motivated and talented experts that help brands including enterprises, mid-size businesses and start-ups with innovative web development services.</p>
                                    <p>eWise's motive is to turn innovative ideas of people into reality! And this is exactly what we want to be known for!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4">
                        <div className="inner relative">
                            <div className="thumb w-auto relative max-w-full sm:max-w-350 md:max-w-352 lg:max-w-384 xl:max-w-376 2xl:max-w-509 m-auto">
                                <img className='lazyload' loading="lazy" data-src={MainServiceBannerImage} alt="Services"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
    <section className="relative pb-10 md:pb-12 lg:pb-16 xl:pb-20">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content px-4 pb-9">
                <h4 id="e-commerce" className=" text-26 md:text-28 lg:text-30 xl:text-32 2xl:text-35"><strong>e-Commerce Development Services
                </strong><span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
            </div>
            <div className="service_wrapper flex flex-wrap">
				<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5">
					<div className="inner flex flex-wrap justify-center">
						<Link to="/shopify-web-development-services" title="Shopify Development" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
							<div className="w-full h-full flex justify-center items-center">
								<img src={ShopifyLogo} alt="Shopify Development"/>
							</div>
						</Link>
                   
					</div>
					<h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Shopify Development" to="/shopify-web-development-services">Shopify <br/> Development</Link></h6>
				</div>
				<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5">
					<div className="inner flex flex-wrap justify-center">
						<Link to="/magento-web-development-services" title="Magento Development" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
							<div className="w-full h-full flex justify-center items-center">
								<img src={MagentoLogo} alt="Magento Development"/>
							</div>
						</Link>
					</div>
					<h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Magento Development" to="/magento-web-development-services">Magento <br/> Development</Link></h6>
				</div>
            </div>
        </div>
    </div>
</section>
<section className="relative pb-10 md:pb-12 lg:pb-16 xl:pb-20">
	<div className="container">
		<div className="-mx-4">
		  <div className="text_content px-4 pb-9">
			  <h4 id="web-development" className="text-26 md:text-28 lg:text-30 xl:text-32 2xl:text-35"><strong>Web Development Services
			  </strong><span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
		  </div>
		  <div className="service_wrapper flex flex-wrap">
			<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5">
				<div className="inner flex flex-wrap justify-center">
					<Link to="/laravel-web-development-services" title="Laravel Development" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
						<div className="w-full h-full flex justify-center items-center">
							<img src={LaravelLogo} alt="Laravel Development"/>
						</div>
					</Link>
				</div>
				<h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Laravel Development" to="/laravel-web-development-services">Laravel <br/> Development</Link></h6>
			</div>
			<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5">
				<div className="inner flex flex-wrap justify-center">
					<Link to="/codeigniter-web-development-services" title="Codeigniter Development" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
							<div className="w-full h-full flex justify-center items-center" >
									<img src={CodeigniterLogo} alt="Codeigniter Development"/>
							</div>
					</Link>
				</div>
				<h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Codeigniter Development" to="/codeigniter-web-development-services">Codeigniter <br/> Development</Link></h6>
			</div>
			<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5 pt-8 md:pt-0">
				<div className="inner flex flex-wrap justify-center">
					<Link to="/php-web-development-services" title="PHP Development" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
						<div className="w-full h-full flex justify-center items-center">
							<img src={PhpLogo} alt="PHP Development"/>
						</div>
					</Link>
				</div>
				<h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="PHP Development" to="/php-web-development-services">PHP <br/> Development</Link></h6>
			</div>
			<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5 pt-8 lg:pt-0">
                <div className="inner flex flex-wrap justify-center">
                    <Link to="/wordpress-web-development-services" title="Wordpress Development" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
                        <div className="w-full h-full flex justify-center items-center">
                            <img src={WordpressLogo} alt="Wordpress Development"/>
                        </div>
                    </Link>
                </div>
                <h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Wordpress Development" to="/wordpress-web-development-services">Wordpress <br/> Development</Link></h6>
            </div>
		  </div>
	  </div>
</div>
</section>
<section className="relative pb-10 md:pb-12 lg:pb-16 xl:pb-20">
	<div className="container">
		<div className="-mx-4">
		  <div className="text_content px-4 pb-9">
			  <h4 id="mobile-app-development" className=" text-26 md:text-28 lg:text-30 xl:text-32 2xl:text-35"><strong>Mobile App Development Services
			  </strong><span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
		  </div>
		  <div className="service_wrapper flex flex-wrap">
			<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5">
				<div className="inner flex flex-wrap justify-center">
					<Link to="/android-app-development-services" title="Android Development" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
							<div className="w-full h-full flex justify-center items-center" >
									<img className="" src={AndroidLogo} alt="Android Development"/>
							</div>
					</Link>
				</div>
				<h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Android Development" to="/android-app-development-services">Android <br/> Development</Link></h6>
			</div>
			
<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5">
    <div className="inner flex flex-wrap justify-center">
        <Link to="/ios-app-development-services" title="ios Development" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
                <div className="w-full h-full flex justify-center items-center" >
                        <img src={AppleLogo} alt="ios Development"/>
                </div>
        </Link>
    </div>
    <h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="ios Development" to="/ios-app-development-services">ios <br/> Development</Link></h6>
</div>
<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5 pt-8 md:pt-0">
    <div className="inner flex flex-wrap justify-center">
            <Link to="/flutter-app-development-services" title="Flutter Development" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
                    <div className="w-full h-full flex justify-center items-center" >
                            <img src={FlutterLogo} alt="Flutter Development"/>
                    </div>
            </Link>
    </div>
    <h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Flutter Development" to="/flutter-app-development-services">Flutter <br/> Development</Link></h6>
</div>
		  </div>
	  </div>
  </div>

</section>
<section className="relative pb-10 md:pb-12 lg:pb-16 xl:pb-21">
	<div className="container">
		<div className="-mx-4">
		  <div className="text_content px-4 pb-9">
			  <h4 id="other-services" className="text-26 md:text-28 lg:text-30 xl:text-32 2xl:text-35"><strong>Other Services
			  </strong><span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
		  </div>
		  <div className="service_wrapper flex flex-wrap">
			<div className="box w-1/2 md:w-1/3 lg:w-1/4 2xl:w-1/5">
				<div className="inner flex flex-wrap justify-center">
					<Link to="/graphics-design-services" title="Graphics Design" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
						<div className="w-full h-full flex justify-center items-center">
							<img src={GraphicLogo} alt="Graphics Design"/>
						</div>
					</Link>
				</div>
				<h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Graphics Design" to="/graphics-design-services">Graphics <br/> Design</Link></h6>
			</div>
			<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5">
				<div className="inner flex flex-wrap justify-center">
					<Link to="/quality-analysis" title="Quality Analysis" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
						<div className="w-full h-full flex justify-center items-center">
							<img src={QaLogo} alt="Quality Analysis"/>
						</div>
					</Link>
				</div>
				<h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Quality Analysis" to="/quality-analysis">Quality <br/> Analysis</Link></h6>
			</div>
			<div className="box w-1/2 md:w-1/3  lg:w-1/4 2xl:w-1/5 pt-8 md:pt-0">
				<div className="inner flex flex-wrap justify-center">
					<Link to="/digital-marketing" title="Digital Marketing" className="circle transition duration-150 ease-in-out hover:bg-lightblue hover:border-white bg-white rounded-full w-120 h-120 sm:w-150 sm:h-150 inline-block p-7 border-16 border-lightblue shadow-service">
						<div className="w-full h-full flex justify-center items-center">
							<img src={DigitalMarketingLogo} alt="Digital Marketing"/>
						</div>
					</Link>
				</div>
				<h6 className="mt-4 text-center text-base sm:text-lg"><Link className="text-black hover:text-pink transition duration-150 ease-in-out" title="Digital Marketing" to="/digital-marketing">Digital <br/> Marketing</Link></h6>
			</div>
		  </div>
	  </div>
  </div>
</section>
<section className="pb-8 sm:pb-10 lg:pb-14">
<PortfolioService>

</PortfolioService>
</section>
    </Layout>
  )
}

export default ServicesPage